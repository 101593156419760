import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import {openInNewTab} from "../function/function";
import web1 from '../../assets/img/siteweb/1.png'
import web2 from '../../assets/img/siteweb/2.png'
import web3 from '../../assets/img/siteweb/VOIXDUNORD.png'


export default function Blog() {
  return (
    <Wrapper id="blog" >
      <div className="whiteBg">
        <div className="container" style={{    paddingBottom: '40px'}}>
          <HeaderInfo>
            <h3 className="font30 extraBold" style={{textAlign:'center',paddingTop:'29px'}}>Web de la région</h3>
            {/*<p className="font13">*/}
            {/*  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut*/}
            {/*  <br />*/}
            {/*  labore et dolore magna aliquyam erat, sed diam voluptua.*/}
            {/*</p>*/}
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                // title="Office de tourisme "
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                // tag="company"
                // author="Luke Skywalker, 2 days ago"
                action={() => openInNewTab('https://tourisme-lenslievin.fr/')}

                img={web1}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                // title="Une petite braderie ?!"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
                action={() => openInNewTab('http://www.sabradou.com/')}

                img={web2}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                // title="Des idées sorties"
                text="Un groupe facebook assez actif"


                img={web3}
                action={() => openInNewTab('https://www.lavoixdunord.fr/le-mag/votre-week-end')}
              />
            </div>
          </div>

          {/*<div className="row textCenter">*/}
          {/*  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
          {/*    <BlogBox*/}
          {/*      title="New Office!"*/}
          {/*      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
          {/*      tag="company"*/}
          {/*      author="Luke Skywalker, 2 days ago"*/}
          {/*      action={() => alert("clicked")}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
          {/*    <BlogBox*/}
          {/*      title="New Office!"*/}
          {/*      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
          {/*      tag="company"*/}
          {/*      author="Luke Skywalker, 2 days ago"*/}
          {/*      action={() => alert("clicked")}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">*/}
          {/*    <BlogBox*/}
          {/*      title="New Office!"*/}
          {/*      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."*/}
          {/*      tag="company"*/}
          {/*      author="Luke Skywalker, 2 days ago"*/}
          {/*      action={() => alert("clicked")}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="row flexCenter">*/}
          {/*  <div style={{ margin: "50px 0", width: "200px" }}>*/}
          {/*    <FullButton title="Load More" action={() => alert("clicked")} />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          {/*<HeaderInfo>*/}
          {/*  <h1 className="font40 extraBold">What They Say?</h1>*/}
          {/*  <p className="font13">*/}
          {/*    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut*/}
          {/*    <br />*/}
          {/*    labore et dolore magna aliquyam erat, sed diam voluptua.*/}
          {/*  </p>*/}
          {/*</HeaderInfo>*/}
          {/*<TestimonialSlider />*/}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
