import * as React from "react";

function SvgComponent(props) {
  return (
      <svg height="50px" width="50px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
           viewBox="0 0 512 512" xmlSpace="preserve">
<path fill="#507C5C" d="M460.878,492.991H51.122c-20.603,0-37.364-16.76-37.364-37.362V135.415
	c0-8.441,6.843-15.285,15.285-15.285s15.285,6.845,15.285,15.285v320.213c0,3.745,3.048,6.791,6.793,6.791h409.756
	c3.745,0,6.793-3.046,6.793-6.791V257.711c0-8.441,6.843-15.285,15.285-15.285s15.285,6.845,15.285,15.285v197.918
	C498.242,476.232,481.481,492.991,460.878,492.991z"/>
          <path fill="#CFF09E" d="M496.715,164.731V82.607c0-12.193-9.885-22.077-22.078-22.077H37.364
	c-12.193-0.002-22.078,9.884-22.078,22.077v82.126h481.429V164.731z"/>
          <g>
	<path fill="#507C5C" d="M496.715,180.018H15.285C6.843,180.018,0,173.173,0,164.733V82.607
		c0-20.603,16.76-37.362,37.364-37.362h437.272C495.24,45.245,512,62.005,512,82.607v82.126
		C512,173.173,505.157,180.018,496.715,180.018z M30.571,149.447h450.858v-66.84c0-3.745-3.048-6.791-6.793-6.791H37.364
		c-3.745,0-6.793,3.046-6.793,6.791V149.447z"/>
              <path fill="#507C5C" d="M76.774,104.099c-8.442,0-15.285-6.845-15.285-15.285V34.294c0-8.441,6.843-15.285,15.285-15.285
		S92.06,25.854,92.06,34.294v54.519C92.06,97.254,85.216,104.099,76.774,104.099z"/>
              <path fill="#507C5C" d="M166.387,104.099c-8.442,0-15.285-6.845-15.285-15.285V34.294c0-8.441,6.843-15.285,15.285-15.285
		s15.285,6.845,15.285,15.285v54.519C181.672,97.254,174.829,104.099,166.387,104.099z"/>
              <path fill="#507C5C" d="M345.613,104.099c-8.442,0-15.285-6.845-15.285-15.285V34.294c0-8.441,6.843-15.285,15.285-15.285
		c8.442,0,15.285,6.845,15.285,15.285v54.519C360.899,97.254,354.055,104.099,345.613,104.099z"/>
              <path fill="#507C5C" d="M255.999,104.099c-8.442,0-15.285-6.845-15.285-15.285V34.294c0-8.441,6.843-15.285,15.285-15.285
		c8.442,0,15.285,6.845,15.285,15.285v54.519C271.285,97.254,264.441,104.099,255.999,104.099z"/>
              <path fill="#507C5C" d="M435.226,104.099c-8.442,0-15.285-6.845-15.285-15.285V34.294c0-8.441,6.843-15.285,15.285-15.285
		s15.285,6.845,15.285,15.285v54.519C450.511,97.254,443.668,104.099,435.226,104.099z"/>
              <path fill="#507C5C" d="M254.162,359.974c-4.054,0-7.942-1.611-10.808-4.477l-47.382-47.382
		c-5.969-5.969-5.969-15.648,0-21.618c5.969-5.967,15.648-5.966,21.617,0l36.572,36.574l69.637-69.637
		c5.969-5.967,15.648-5.967,21.618,0c5.969,5.969,5.969,15.648,0,21.618l-80.446,80.446
		C262.104,358.363,258.217,359.974,254.162,359.974z"/>
              <path fill="#507C5C" d="M360.672,416.568H151.328c-8.442,0-15.285-6.845-15.285-15.285V237.839
		c0-8.441,6.843-15.285,15.285-15.285h94.991c8.442,0,15.285,6.845,15.285,15.285c0,8.441-6.843,15.285-15.285,15.285h-79.706
		v132.875h178.774V320.57c0-8.441,6.843-15.285,15.285-15.285c8.442,0,15.285,6.845,15.285,15.285v80.715
		C375.958,409.725,369.115,416.568,360.672,416.568z"/>
</g>
</svg>
  );
}

export default SvgComponent;
