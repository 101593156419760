import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg7 from "../../assets/img/projects/7.png";
import AddImage2 from "../../assets/img/add/add2.png";
import {openInNewTab} from "../function/function";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h3 className="font30 extraBold" style={{textAlign: 'center',paddingTop: '29px'}}>Les choses à voir d'min coin</h3>
            {/*<p className="font13">*/}
            {/*  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut*/}
            {/*  <br />*/}
            {/*  labore et dolore magna aliquyam erat, sed diam voluptua.*/}
            {/*</p>*/}
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Le stade mythique de Lens !"
                text="Une ambiance unique, un moment inoubliable !"
                action={() => openInNewTab('https://billetterie.rclens.fr/fr')}



              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Le mémorial canadien de Vimy"
                text="Un musée, des visites guidées des souterrains, une balade au coeur de l'histoire."
                action={() => openInNewTab('https://nord-decouverte.fr/memorial-canadien-de-vimy-chemins-de-memoire/')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Les terrils."
                text="Majestueux terrils, les symboles d'une région."
                action={() => openInNewTab('https://www.pas-de-calais-tourisme.com/fr/montez-au-sommet-dun-terril/')}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Le musée du Louvre-Lens"
                text="Un musée digne des plus grands, des oeuvres du monde entiers, à ne pas manquer!"
                action={() => openInNewTab('https://www.louvrelens.fr/')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Les places d'Arras"
                text="A 8mn de la maison, le coeur d'Arras est à voir."
                action={() => openInNewTab('https://www.arraspaysdartois.com/les-incontournables/les-places-darras-la-dolce-vita/')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg7}
                title="Le parc d'Olhain"
                text="Accès direct par la rocade, un parc rempli d'activités diverses et variées."
                action={() => openInNewTab('https://www.parcdolhain.fr/')}
              />
            </div>
          </div>
          {/*<div className="row flexCenter">*/}
          {/*  <div style={{ margin: "50px 0", width: "200px" }}>*/}
          {/*    <FullButton title="Load More" action={() => alert("clicked")} />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
      {/*<div className="lightBg">*/}
      {/*  <div className="container">*/}
      {/*    <Advertising className="flexSpaceCenter">*/}
      {/*      <AddLeft>*/}
      {/*        <AddLeftInner>*/}
      {/*          <ImgWrapper className="flexCenter">*/}
      {/*            <img className="radius8" src={AddImage2} alt="add" />*/}
      {/*          </ImgWrapper>*/}
      {/*        </AddLeftInner>*/}
      {/*      </AddLeft>*/}
      {/*      <AddRight>*/}
      {/*        <h4 className="font15 semiBold">A few words about company</h4>*/}
      {/*        <h2 className="font40 extraBold">A Study of Creativity</h2>*/}
      {/*        <p className="font12">*/}
      {/*          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed*/}
      {/*          diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.*/}
      {/*        </p>*/}
      {/*        <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>*/}
      {/*          <div style={{ width: "190px" }}>*/}
      {/*            <FullButton title="Get Started" action={() => alert("clicked")} />*/}
      {/*          </div>*/}
      {/*          <div style={{ width: "190px", marginLeft: "15px" }}>*/}
      {/*            <FullButton title="Contact Us" action={() => alert("clicked")} border />*/}
      {/*          </div>*/}
      {/*        </ButtonsRow>*/}
      {/*      </AddRight>*/}
      {/*    </Advertising>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          {/*<HeaderInfo>*/}
          {/*  <h1 className="font40 extraBold">What They Say?</h1>*/}
          {/*  <p className="font13">*/}
          {/*    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut*/}
          {/*    <br />*/}
          {/*    labore et dolore magna aliquyam erat, sed diam voluptua.*/}
          {/*  </p>*/}
          {/*</HeaderInfo>*/}
          {/*<TestimonialSlider />*/}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
