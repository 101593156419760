import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import add from "../../style/add.css";

// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
// import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import logo_avion from "../../assets/img/projects/LOGO-LACASA-AVION-400.png";
import GPSIcon from "../Sections/GPSicon";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" ,top:'25px'} : { height: "80px",top:'25px' }}>
              <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            {/*<LogoIcon />*/}
            <img src={logo_avion} alt={'logo'}
                 style={{height:'75px'}}
            />
            {/*<h1 style={{ marginLeft: "15px" }} className="font20 extraBold">*/}
            {/*  Gite*/}
            {/*</h1>*/}


          </Link>
          {/*<button className="button-10" role="button"*/}
          {/*>Nous trouver</button>*/}


          <div>
            <a href="https://goo.gl/maps/QeN1HFzq1qvAZzDW6"
               target="_blank"
               style={{ padding: "10px 30px 10px 0" }}>
              <button className="button-10"
                  // role="button"

                      onClick="window.location.href='https://goo.gl/maps/QeN1HFzq1qvAZzDW6'"
              >Nous trouver</button>
            </a></div>


          <BurderWrapper style={{paddingLeft:'20px'}} className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter dispFlexColumn">
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                Accueil
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active dispFlex" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                Le gite
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                A faire dans le coin
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="blog" spy={true} smooth={true} offset={-80}>
                Web sympa
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
                Prix
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                Contact
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            {/*<li className="semiBold font15 pointer">*/}
            {/*  <a href="/" style={{ padding: "10px 30px 10px 0" }}>*/}
            {/*    Mon compte*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li className="semiBold font15 pointer flexCenter">*/}
            {/*  <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>*/}
            {/*   */}
            {/*  </a>*/}
            {/*</li>*/}
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}
// position: fixed;
const Wrapper = styled.nav`
  width: 100%;

  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


