import React from "react";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img3 from '../../assets/img/carroussel/3.jpg'
import img4 from '../../assets/img/carroussel/4.jpg'
import img5 from '../../assets/img/carroussel/5.jpg'
import img6 from '../../assets/img/carroussel/6.jpg'
import img7 from '../../assets/img/carroussel/7.jpg'
import img8 from '../../assets/img/carroussel/8.jpg'
import img9 from '../../assets/img/carroussel/9.jpg'






export default function CarrousselBox() {

    let slideStyle= {
        position: 'absolute',
        display: 'block',
        zIndex: -2,
        minHeight: '100%',
        opacity: 0,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        // transitionTimingFunction: transitionTimingFunction,
        // msTransitionTimingFunction: transitionTimingFunction,
        // MozTransitionTimingFunction: transitionTimingFunction,
        // WebkitTransitionTimingFunction: transitionTimingFunction,
        // OTransitionTimingFunction: transitionTimingFunction,
    };

    let style={
        MaxHeight:'500px'
    }

    return (
        <Carousel autoPlay
                  infiniteLoop
                  showArrows
                  useKeyboardArrows
                  swipeable
                  stopOnHover
                  // width="600px"
                  // height="100px"
        >
                      <div>
                <img style={style} src={img3} alt={'image1'}/>
                {/*<p className="legend">Legend 1</p>*/}
            </div>
            <div>
                <img style={style} src={img4} alt={'image1'}/>
                {/*<p className="legend">Legend 1</p>*/}
            </div>
            <div>
                <img style={style} src={img5} alt={'image1'}/>
                {/*<p className="legend">Legend 1</p>*/}
            </div>
            <div>
                <img style={style} src={img6} alt={'image1'}/>
                {/*<p className="legend">Legend 1</p>*/}
            </div>
            <div>
                <img style={style} src={img7} alt={'image1'}/>
                {/*<p className="legend">Legend 1</p>*/}
            </div>
            <div>
                <img style={style} src={img8} alt={'image1'}/>
                {/*<p className="legend">Legend 1</p>*/}
            </div>
            <div>
                <img style={style} src={img9} alt={'image1'}/>
                {/*<p className="legend">Legend 1</p>*/}
            </div>
        </Carousel>
    )
}
