import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
// import LogoIcon from "../../assets/svg/Logo";
import logo_avion from "../../assets/img/projects/LOGO-LACASA-AVION-400.png";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {

console.log ( ' sidebar open : ',sidebarOpen)
console.log ( 'window width: ', window.screen.width*-1+"px")
  // const [windowDimension, setWindowDimension] = useState(null);
  //
  // useEffect(() => {
  //   setWindowDimension(window.innerWidth);
  // }, []);
  //
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimension(window.innerWidth);
  //   }
  //
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  //
  // const isMobile = windowDimension <= 640;


  const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: ${sidebarOpen ? "absolute" : "fixed"};
  top: 0;
  padding: 0 30px;
  right: ${sidebarOpen ? "0px" : window.screen.width*-1+"px"};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
    
  }
`;
  const SidebarHeader = styled.div`
  padding: 20px 0;
`;
  const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
  const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;




  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          {/*<LogoIcon />*/}
          <img style={{width:'100px'}} src={logo_avion} alt={'logo'}/>
          {/*<h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>*/}
          {/*  fanatic*/}
          {/*</h1>*/}
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Accueil
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Services
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-60}
          >
            A faire dans le coin
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Blog
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Prix
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Contact
          </Link>
        </li>
      </UlStyle>
      <UlStyle className="flexSpaceCenter">
        {/*<li className="semiBold font15 pointer">*/}
        {/*  <a href="/" style={{ padding: "10px 30px 10px 0" }} className="whiteColor">*/}
        {/*    Mon compte*/}
        {/*  </a>*/}
        {/*</li>*/}
        <li className="semiBold font15 pointer flexCenter">
          <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            Réserver
          </a>
        </li>
      </UlStyle>
    </Wrapper>
  );
}


