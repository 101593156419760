

export function openInNewTab(url) {
    window.open(url, '_blank').focus();
}

export function phoneCall(number){
    // window.open(number)
    window.location.href = 'tel://' + number;
}

/* SmtpJS.com - v3.0.0 */
// const Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };
//
// export  async function  sendEmailValidationRequest  (email){
//         Email.send({
//             Host: "smtp.gmail.com",
//             Username: "sender@email_address.com",
//             Password: "Enter your password",
//             To: 'receiver@email_address.com',
//             From: "sender@email_address.com",
//             Subject: "Sending Email using javascript",
//             Body: "Well that was easy!!",
//         })
//             .then(function (message) {
//                 alert("mail sent successfully")
//             });
//
// }
