import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";
import {openInNewTab} from "../function/function";

export default function PricingTable({ icon, price, title, text,  offers, action }) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font25 extraBold" >{price}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font23 extraBold" style={{textAlign: 'right'}}>{title}</h4>
        <p className="font19">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.cheked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font20 extraBold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      {/*<div style={{ maxWidth: "120px", margin: "30px auto 0 auto" }}>*/}
      {/*  <FullButton title="Buy" action={action} />*/}
      {/*</div>*/}
        <BtnWrapper style={{display: 'contents'}}>
            <FullButton title="Réserver sur AIRBNB"
                        action={() => openInNewTab('https://www.airbnb.fr/rooms/853465536637804382?check_in=2023-04-17&check_out=2023-04-18&guests=1&adults=1&s=67&unique_share_id=064a33c5-9ce7-4b46-8280-c642c3dbfb22')}
            />

        </BtnWrapper>
        <div style={{textAlign:'center'}}><br/>
        <h4>ou</h4><br/>
            <h4 >Infos et réservation : 06 26 33 11 02</h4><br/>


        </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
