import React, {useEffect, useState} from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";
import planAvion from "../../assets/img/carte avion lens - .png";
import {openInNewTab, phoneCall} from "../function/function";

export default function Services() {

  const [y, setY] = useState(window.scrollY);
  const [calendarWidth, setCalendarWidth] = useState('500px');

  const largeurDivCalendar = 650
  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.innerWidth));
    // return () => {
    //   window.removeEventListener("scroll", () => setY(window.scrollY));
    // };
  }, []);

  useEffect(()=>{
    console.log (' y : ', y)
    if(y>1000){
      setCalendarWidth('500px')
    }
    if(y<1000 && y>600){
      setCalendarWidth(y/2+'px')
    }
    if(y<599){
      setCalendarWidth(y-50+'px')
    }


  },[y])

  useEffect(()=>{
  },[calendarWidth])

  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "9px 0" }}>
        <div className="container" style={ y<largeurDivCalendar ?{}:{justifyContent: 'space-evenly',display: 'flex'}}>
          <div style={
            y<largeurDivCalendar ?
            {display: 'flex',
              flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'nowrap'}
            :

            {    display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'}}>
            <h2 className="font30 extraBold">Nos disponibiltés</h2>
            <p id={"descService"} className="font20">
              <ul>
                <li>Dates pouvant être modifiées</li>
                <li>Veuillez appeler pour être sûr !</li>

              </ul>
            </p>
            <BtnWrapper style={y<largeurDivCalendar ?{display:'none'}:{display: 'contents',marginTop:'9px'}}>
              <FullButton title="Réserver sur AIRBNB"
                          action={() => openInNewTab('https://www.airbnb.fr/rooms/853465536637804382?check_in=2023-04-17&check_out=2023-04-18&guests=1&adults=1&s=67&unique_share_id=064a33c5-9ce7-4b46-8280-c642c3dbfb22')}
              />

            </BtnWrapper>
            <div style={y<largeurDivCalendar ?{display:'none'}:{textAlign:'center'}}>
              <br/>
              <h4>ou</h4><br/>
              <p className={'font20'} id='headerPhone' style={{fontWeight:'bold'}}>Infos et réservation : <a href={"tel:+33626331102"} style={{textDecoration:'underline'}}>06 26 33 11 02</a></p><br/>


            </div>

          </div>
          <div style={{minHeight:'500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'}}>
            <iframe
                src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&showTitle=0&showTabs=0&showCalendars=0&showTz=0&src=amFnNDZkZDYwcm9tbjB2bm41b2p0cGszcW1zZW5sY3JAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23AD1457"
                style={{border: "solid 1px #777", width:calendarWidth, height: "400px", frameBorder: "0px", scrolling: "no"}}
            />
          </div>


          {/*<ClientSlider />*/}
          {/*<img src={planAvion}  style={{width:'100%'}} alt={'plan avion lens'}/>*/}
        </div>
      </div>
      <div className="whiteBg" style={{  }}>

        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                {/*<h4 className="font15 semiBold">A few words about company</h4>*/}
                <h2 className="font30 extraBold">Le gite :</h2>
                <p id={"descService"} className="font25">
                 <ul>
                   <li>-2 chambres (1 lit double + 1 lit superposé)</li>
                   <li>-1 pièce à vivre avec canapé convertible</li>
                   <li>-Cuisine equipée</li>
                   <li>-Terrasse extérieure, barbecue à dispo</li>
                   <li>-Salle de bain avec baignoire</li>
                   <li>-Serviettes et draps fournis</li>
                   <li>-Lave linge</li>
                   <li>-Entrée indépendante et discrète (accès direct par code)</li>
                   <li>-Wifi Fibre + TV (films en accés libre)</li>
                   <li>-Parking</li>
                 </ul>
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Réserver sur Airbnb"
                                action={() => openInNewTab('https://www.airbnb.fr/rooms/853465536637804382?check_in=2023-04-17&check_out=2023-04-18&guests=1&adults=1&s=67&unique_share_id=064a33c5-9ce7-4b46-8280-c642c3dbfb22')}
                    />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Nous appeler" action={() => phoneCall("+33626331102")} background={"green"} />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <img src={planAvion}  style={{width:'100%'}} alt={'plan avion lens'}/>
                  {/*  <AddImgWrapp1 className="flexCenter">*/}
                  {/*    <img src={AddImage1} alt="office" />*/}
                  {/*  </AddImgWrapp1>*/}
                  {/*  <AddImgWrapp2>*/}
                  {/*    <img src={AddImage2} alt="office" />*/}
                  {/*  </AddImgWrapp2>*/}
                  {/*</div>*/}
                  {/*<div className="flexNullCenter">*/}
                  {/*  <AddImgWrapp3>*/}
                  {/*    <img src={AddImage3} alt="office" />*/}
                  {/*  </AddImgWrapp3>*/}
                  {/*  <AddImgWrapp4>*/}
                  {/*    <img src={AddImage4} alt="office" />*/}
                  {/*  </AddImgWrapp4>*/}
                  </div>
                </AddRightInner>

              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 20px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;

  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
