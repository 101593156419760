import * as React from "react";

function SvgComponent(props) {
    return (

        <svg height="70px" width="70px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 390.556 390.556" xmlSpace="preserve">
<path fill="#56ACE0" d="M287.367,62.319c0,15.709,22.238,53.657,40.663,81.325c18.424-27.669,40.663-65.552,40.663-81.325
	c0-22.238-18.424-40.663-40.663-40.663C305.274,21.657,287.367,40.081,287.367,62.319z"/>
            <g>
	<path fill="#194F82" d="M328.03,0c-34.715,0-62.319,28.186-62.319,62.319c0,30.901,48.226,99.168,53.657,106.796
		c4.331,5.947,12.994,5.947,17.325,0c5.43-7.564,53.657-75.313,53.657-106.796C390.349,28.186,362.163,0,328.03,0z M368.692,62.319
		c0,15.709-22.238,53.657-40.663,81.325c-18.424-27.669-40.663-65.552-40.663-81.325c0-22.238,18.424-40.663,40.663-40.663
		C350.268,21.657,368.692,40.081,368.692,62.319z"/>
                <circle fill="#194F82;" cx="328.03" cy="58.505" r="11.895"/>
</g>
            <path fill="#FFC10D" d="M62.462,238.481c-22.238,0-40.663,18.424-40.663,40.663c0,15.709,22.238,53.657,40.663,81.325
	c18.424-27.669,40.663-65.552,40.663-81.325C103.125,256.323,85.217,238.481,62.462,238.481z"/>
            <g>
	<path fill="#194F82" d="M62.462,216.76c-34.133,0-62.319,27.669-62.319,62.319c0,30.901,48.226,99.168,53.657,106.796
		c4.331,5.947,12.994,6.529,17.325,0c5.43-7.564,53.657-75.313,53.657-106.796C124.781,244.428,97.177,216.76,62.462,216.76z
		 M21.799,279.079c0-22.238,18.424-40.663,40.663-40.663s40.663,18.424,40.663,40.663c0,15.709-22.238,53.657-40.663,81.325
		C44.038,332.735,21.799,294.853,21.799,279.079z"/>
                <circle fill="#194F82" cx="62.462" cy="274.747" r="11.895"/>
                <path fill="#194F82" d="M178.955,285.608h165.301c25.471,0,46.093-20.622,46.093-46.093s-20.622-46.093-46.093-46.093
		H32.143c-5.43,0-10.279-4.331-10.279-10.279c0-5.43,4.331-10.279,10.279-10.279h240.032c5.947,0,10.861-4.848,10.861-10.861
		s-4.848-10.861-10.861-10.861H32.143c-17.325,0-32,14.093-32,32c0,17.39,14.093,32,32,32H344.32
		c13.576,0,24.372,10.861,24.372,24.372c0,13.576-10.861,24.372-24.372,24.372H178.955c-19.523,0-34.715,15.709-34.715,34.715
		c0,19.523,15.709,34.715,34.715,34.715h172.348c9.762,0,17.325,7.564,17.325,17.325s-7.564,17.325-17.325,17.325H118.316
		c-5.947,0-10.861,4.849-10.861,10.861c0,6.012,4.848,10.861,10.861,10.861h233.051c21.657,0,39.046-17.325,39.046-39.047
		c0-21.721-17.325-39.046-39.046-39.046H178.955c-7.046,0-12.994-5.947-12.994-12.994
		C165.444,291.556,171.391,285.608,178.955,285.608z"/>
</g>
</svg>


    )
}
export default SvgComponent;
